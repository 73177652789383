import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'


const ThankYou = () => {
  
  return (
    <>
      <Jumbotron fluid>
        <Container>
          <h1>Registrering genomförd</h1>
          <p>
            Du kan nu logga in i appen Brandfarliga arbeten
          </p>
        </Container>
      </Jumbotron>
    </>
  )
}

export default ThankYou