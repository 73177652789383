import React, { useState } from 'react';
import { useFormik } from 'formik'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import { Redirect } from 'react-router-dom'

const CreateInvitedUser = () => {
  const [redirect, setRedirect] = useState(false);
  

    // Pass the useFormik() hook initial form values and a submit function that will
    // be called when the form is submitted
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      password2: '',
      profile: {
        first_name: '',
        last_name: '',
        cell_no: '',
      }
    },
    onSubmit: async values => {
      // alert(JSON.stringify(values, null, 2));
      try {
        const response = await axios
          .post(`http://localhost:8000/api/auth/users/`, values);
        return (
          setRedirect(true)
        );
      } catch (error) {
        console.error(error);
        return error;
      }
    },
  })

  if (redirect) {
    return <Redirect to='/thankyou'/>;
  }
  
  return (
    <Container>
      <Jumbotron>
        <Container>
          <h1>Registrera</h1>
          <p>Du som har fått inbjudan kan registrera dig här</p>
        </Container>
      </Jumbotron>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group controlId="first_name">
          <Form.Label>Förnamn</Form.Label>
          <Form.Control
            name="profile.first_name"
            type="text"
            placeholder="Lars"
            onChange={formik.handleChange} 
            value={formik.values.profile.first_name}
          />
        </Form.Group>

        <Form.Group controlId="last_name">
          <Form.Label>Efternamn</Form.Label>
          <Form.Control
            name="profile.last_name"
            type="text"
            placeholder="Magnusson"
            onChange={formik.handleChange} 
            value={formik.values.profile.last_name}
          />
        </Form.Group>

        <Form.Group controlId="cell_no">
          <Form.Label>Mobilnummer</Form.Label>
          <Form.Control
            name="profile.cell_no"
            type="text"
            placeholder="0701 23 45 67"
            onChange={formik.handleChange} 
            value={formik.values.profile.cell_no}
          />
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>E-post</Form.Label>
          <Form.Control
            name="email"
            type="email"
            placeholder="namn@doman.com"
            onChange={formik.handleChange} 
            value={formik.values.email}
          />
        </Form.Group>

        <Form.Group controlId="password1">
          <Form.Label>Lösenord</Form.Label>
          <Form.Control
            name="password"
            type="password"
            placeholder="Lösenord"
            onChange={formik.handleChange} 
            value={formik.values.password}
          />
        </Form.Group>


        <Form.Group controlId="password2">
          <Form.Label>Upprepa lösenord</Form.Label>
          <Form.Control
            name="password2"
            type="password"
            placeholder="Lösenord"
            onChange={formik.handleChange} 
            value={formik.values.password2}
          />
        </Form.Group>

        <Button variant="primary" type="submit">Submit</Button>
      </Form>
    </Container>
  )
}

export default CreateInvitedUser